<template>
  <v-main>
    <v-container>
      <v-card class="mx-auto" max-width="600">
        <v-card-title> Password Reset </v-card-title>
        <v-container>
          <p>
            Forgot your password? Enter your email address and we’ll send you a
            link to click that will help you reset your password.
          </p>
          <account-recovery></account-recovery>
        </v-container>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import AccountRecovery from '@/components/AccountRecovery'
export default {
  metaInfo: {
    title: 'Account Recovery'
  },

  components: {
    AccountRecovery
  }
}
</script>

<style></style>
