<template>
  <v-form ref="form" v-model="valid" @submit.prevent="sendRecoveryEmail()">
    <v-alert type="success" v-if="success">{{ success }}</v-alert>
    <v-alert type="error" v-if="error">{{ error }}</v-alert>
    <v-text-field
      v-model="email"
      type="email"
      label="Email"
      :rules="rules.emailRule"
      outlined
    ></v-text-field>
    <v-btn
      :loading="loading"
      class="text-capitalize"
      color="success"
      large
      @click="sendRecoveryEmail()"
      >Send reset link</v-btn
    >
  </v-form>
</template>

<script>
import rules from '@/mixins/rules'
import firebase from 'firebase/app'
import config from '../config'
const api = config.api()
import 'firebase/auth'
export default {
  mixins: [rules],
  data: () => ({
    email: '',
    valid: true,
    success: null,
    error: null,
    loading: false
  }),
  methods: {
    async sendRecoveryEmail() {
      this.$refs.form.validate()
      if (this.valid) {
        this.loading = true
        this.success = null
        this.error = null
        try {
          const res = await this.$axios.get(
            `${api.cloudFunction}/resetPassword?email=${this.email}&site=peeps`
          )
          this.success = res.data.message
        } catch (err) {
          this.loading = false
          if (err.response && err.response.data.message) {
            this.error = err.response.data.message
          } else if (err.message) {
            this.err = err.message
          } else {
            this.err = this.$store.state.ERROR_MESSAGES.default
          }
          this.handleException(err, '\nError sending reset password email')
        }
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
